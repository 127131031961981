

























































import {
  Component, Prop, Vue, Emit,
} from 'vue-property-decorator';
import { snakeCase, capitalCase } from 'change-case';
import _ from 'lodash';

import Message from '@/entities/messages/Message';
import { MessageType } from '../../services/api/models/IServiceMessage';

@Component({
  name: 'message-detail',
})
export default class MessageDetail extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  }) private readonly value!: Message;

  @Prop({
    type: String,
    default: 'source',
  }) private readonly shownUser!: 'target' | 'source';

  private typeOptions = Object.keys(MessageType).filter((k) => typeof MessageType[k as keyof typeof MessageType] === 'string').map((k) => ({
    text: capitalCase(MessageType[k as keyof typeof MessageType]),
    value: MessageType[k as keyof typeof MessageType],
  }));

  getProfilePic(id?: string): string {
    return `${this.$baseUrl}/users/${id || 'me'}/files/profile`;
  }

  @Emit('reply')
  replyToMessage() {
    return this.value;
  }
}
