






















































































































































import {
  Component, Prop, Vue, Mixins, Watch,
} from 'vue-property-decorator';
import _ from 'lodash';
import Axios from 'axios';

import MessageList, { MessageAttachment } from '@/views/messages/MessageList.vue';
import MessageComposer from '@/views/messages/MessageComposer.vue';
import MessageDetail from '@/views/messages/MessageDetail.vue';
import QueryBuilder from '@/views/reports/builder/QueryBuilder.vue';

import UserService from '@/services/users';
import { MessageType } from '@/services/api/models/IServiceMessage';
import IServiceQuery from '@/services/api/models/IServiceQuery';

import Query from '@/entities/queries/Query';
import Message from '@/entities/messages/Message';
import User, { SimpleUser } from '@/entities/User';

import UserPermissions from '@/mixins/UserPermissions.vue';
import PreventDirtyLeave from '@/mixins/PreventDirtyLeave.vue';
import GlobalNotifications from '@/mixins/GlobalNotifications.vue';

import * as config from '@/reportConfigs.json';
import { configMapBuilder } from '@/router';
import { Action, Getter, State } from 'vuex-class';
import Lender from '@/entities/Lender';

@Component({
  name: 'messages',
  components: {
    MessageList,
    MessageComposer,
    MessageDetail,
    QueryBuilder,
  },
})
export default class Messages extends Mixins(UserPermissions, PreventDirtyLeave, GlobalNotifications) {
  // Vuex
  @State((state) => state.lenders.lenders) lenders!: Lender[];
  @State((state) => state.messages.inboxLoading) inboxLoading!: boolean;
  @State((state) => state.messages.sentLoading) sentLoading!: boolean;
  @State((state) => state.messages.sent) sentMessages!: Message[];

  @Getter('inboxMessages') inboxMessages!: Message[];
  @Getter('newsMessages') newsMessages!: Message[];
  @Getter('unreadInboxMessages') unreadInboxMessages!: Message[];
  @Getter('unreadNewsMessages') unreadNewsMessages!: Message[];

  @Action('fetchLenders') fetchLenders!: () => void;
  @Action('fetchMessages') fetchMessages!: () => void;
  @Action('markAsRead') markAsRead!: (messageId: string) => void;
  @Action('sendMessage') sendMessage!: (payload: { message: Message, files: File[] }) => void;
  @Action('deleteMessage') deleteMessage!: (messageId: string) => void;
  @Action('deleteSentBatch') deleteSentBatch!: (batchId: string) => void;

  @Prop({ type: String }) private readonly view!: string;

  private userService: UserService = new UserService();

  private selectedMessage: Message = null;
  private showDetail: boolean = false;

  private users: User[] = [];
  private queries: Query[] = [];

  private isLoading: boolean = false;

  private newMessage: boolean = false;
  private messageType: MessageType = null;
  private replyTarget: SimpleUser = null;

  private showNewQuery: boolean = false;
  private selectedQuery: IServiceQuery = null;
  private selections: Map<string, any[]> = null;

  private tab: any = null;

  // Watchers
  @Watch('newMessage')
  onComposerDialogToggled(dialogShowing: boolean) {
    if (!dialogShowing) {
      this.messageType = null;
      this.replyTarget = null;
    }
  }

  // Computed
  get allowedTypes(): MessageType[] {
    if (this.isAdmin) {
      return null;
    }

    if (this.isLenderAdmin) {
      return [MessageType.direct, MessageType.lenderBroadcast];
    }

    return [MessageType.direct];
  }

  // Hooks
  async created() {
    this.fetchMessages();
    this.refreshUsers();
    this.fetchLenders();
    this.refreshQueries();

    this.selections = configMapBuilder(
      [config.lenderDataReport, config.agencyDataReport, config.loanDataReport],
    );
  }

  refreshUsers() {
    this.userService.getUsers()
      .then((users) => {
        this.users = users;
      });
  }

  refreshQueries() {
    this.userService.getAllUserQueries()
      .then((queries) => {
        this.queries = queries;
      });
  }

  createMessage(message: Message, files: File[]) {
    this.sendMessage({ message, files });
  }

  markMessage(message: Message) {
    this.markAsRead(message.id);
  }

  selectMessage(message: Message) {
    this.showDetail = true;
    this.selectedMessage = message;
  }

  composeReply(message: Message) {
    this.newMessage = true;
    this.messageType = MessageType.direct;
    this.replyTarget = message.source;
  }

  handleAttachment(attachment: MessageAttachment) {
    if (attachment.type === 'file') {
      this.downloadItem(attachment.url, attachment.name);
    } else if (attachment.type === 'query') {
      this.openQueryDialog(attachment.query, attachment.name);
    }
  }

  downloadItem(url: string, name: string) {
    Axios.get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
  }

  openQueryDialog(query: IServiceQuery, name: string) {
    this.selectedQuery = query;
    this.showNewQuery = true;
  }

  onQuerySaved(query: Query) {
    this.showNewQuery = false;

    this.showSuccess({
      text: 'Query cloned successfully',
      actions: {
        text: 'Go to Query',
        function: () => {
          this.$router.push({
            name: 'query-detail',
            params: { id: query.id },
          });
        },
      },
    })
  }

  isDirty() {
    return this.newMessage;
  }

  sentDeleteFilter(message: Message) {
    return !message.target && message.type === 'news';
  }

  newsDeleteFilter(message: Message) {
    return this.isAdmin && this.user.id === message.source.id;
  }
}
