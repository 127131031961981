


















































































import {
  Component, Prop, Vue, Emit,
} from 'vue-property-decorator';

import IServiceQuery from '@/services/api/models/IServiceQuery';

import Message from '@/entities/messages/Message';

export interface MessageAttachment {
  type: 'file' | 'query',
  name: string,
  url?: string,
  query?: IServiceQuery,
}

@Component({
  name: 'message-list',
})
export default class MessageList extends Vue {
  @Prop({
    type: Array,
    default: (): Message[] => [],
  }) private readonly value!: Message[];

  @Prop({
    type: String,
    default: 'source',
  }) private readonly shownUser!: 'target' | 'source';

  @Prop({
    type: [Boolean, Function],
    default: true,
  }) private readonly showDelete!: boolean | ((message: Message) => boolean);

  @Prop({
    type: Boolean,
    default: true,
  }) private readonly showMark!: boolean;

  // Computed
  get allAttachments(): MessageAttachment[][] {
    if (!this.value) return [];

    return this.value.map((message: Message) => {
      const attachments: MessageAttachment[] = message.files.map((file) => ({
        type: 'file',
        name: file.name,
        url: file.url,
      }));

      if (message.query) {
        attachments.push({
          type: 'query',
          name: message.query.name || 'User Query',
          query: message.query,
        });
      }

      return attachments;
    });
  }

  canDelete(message: Message) {
    if (typeof this.showDelete === 'boolean') {
      return this.showDelete;
    }

    return this.showDelete(message);
  }

  @Emit('delete')
  deleteMessage(message: Message) {}

  markMessage(message: Message, mark: boolean = true) {
    this.$emit('mark', message, mark);
  }

  @Emit('select')
  selectMessage(message: Message) {
    this.markMessage(message, true);
  }

  getProfilePic(id: string): string {
    return `${this.$baseUrl}/users/${id}/files/profile`;
  }

  @Emit('handle-attachment')
  handleAttachment(attachment: MessageAttachment) {}
}
