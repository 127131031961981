

































































































import {
  Component, Prop, Watch, Vue,
} from 'vue-property-decorator';
import { capitalCase } from 'change-case';

import { MessagePost } from '@/services/users';
import { MessageType } from '@/services/api/models/IServiceMessage';

import { fileSizeValidator } from '@/validations/vuetify';

import Query from '@/entities/queries/Query';
import User, { SimpleUser } from '@/entities/User';
import Lender from '@/entities/Lender';

import UserSearch from '@/views/users/UserSearch.vue';

@Component({
  name: 'message-composer',
  components: {
    UserSearch,
  },
})
export default class MessageComposer extends Vue {
  @Prop({
    type: Object,
    default: (): SimpleUser => null,
  }) private readonly target!: SimpleUser;

  @Prop({
    type: Array,
    default: (): string[] => null,
  }) private readonly allowedTypes!: string[];

  @Prop({
    type: String,
    default: null,
  }) private readonly type!: MessageType;

  @Prop({
    type: Array,
    default: (): User[] => [],
  }) private readonly users!: User[];

  @Prop({
    type: Array,
    default: (): Lender[] => [],
  }) private readonly lenders!: Lender[];

  @Prop({
    type: Array,
    default: (): Query[] => [],
  }) private readonly queries!: Query[];

  @Watch('target', { immediate: true })
  onTargetChanged(target: SimpleUser) {
    if (target) {
      this.message.target_id = target.id;
    }
  }

  @Watch('type', { immediate: true })
  onTypeChanged(type: MessageType) {
    if (type) {
      this.message.type = type;
    }
  }

  private allTypeOptions = Object.keys(MessageType).filter((k) => typeof MessageType[k as keyof typeof MessageType] === 'string').map((k) => ({
    text: capitalCase(MessageType[k as keyof typeof MessageType]),
    value: MessageType[k as keyof typeof MessageType],
  }));

  private message: MessagePost = {
    target_id: null,
    type: MessageType.direct,
    body: '',
    query: null,
  };

  private files: File[] = [];
  private fileRules: Function[] = [
    fileSizeValidator(15000000, 'File size limit is 15 MB'),
  ];
  private filesValid: boolean = false;

  private pictureUrl: string = `${this.$baseUrl}/users/me/files/profile`;

  // Computed
  get typeOptions(): { text: string; value: string }[] {
    if (!this.allowedTypes) {
      return this.allTypeOptions;
    }

    return this.allTypeOptions.filter((typeOption) => this.allowedTypes.find((type) => type === typeOption.value));
  }

  createMessage() {
    this.$emit('create', this.message, this.files);
  }

  determineLenderName(lender: Lender) {
    return `${lender.name} (${lender.id})`;
  }

  determineUserName(user: User) {
    return `${user} (${user.email})`;
  }
}
